#syncOverlay{
    top: 5%;
    left: 5%;
    width: 90%;
    height: 50%;
    background-color: #f3f3f3;
    position: absolute;
    padding-left:5px;
    padding-right:5px;
    padding-top:5px;
}

#syncHeader{height: 10%;}

#syncElementList{
    height:80%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
}
.syncElement{
    height:100%;
    padding-top: 10px;
    text-align: center;
}

#syncControlPanel{height:10%; padding-top: 5px;}

#syncTitle{
    float: left;

}

#closeSyncOverlayButton{
    float: right;
    width: 25px;
    height: 25px;
    background-color: red;
    color: aliceblue;
    font-weight: bold;
    border-radius: 5px;
    display: inline-flex;
    align-items: center; 
    justify-content: center;
}

.unselectable{
    -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;
    padding-left: 5px;
    padding-top: 5px;
    width: 100%;
}

#cardGroupId{
    gap:5px;
    width:100%;
}