iframe{
  height: 100%;
  border: none;
}

.App,#root{
  height: 100%;
}

.viewer{
  height: 100%;
  flex: 1;
}

.closebutton{
  position: absolute;
  padding:2px 5px;
  margin-left: 5px;
  margin-top: 5px;
}

#viewerList{
  height:95%;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

#controlPanel{
  height:5%;
}

.linkStatus{
  border-radius: 100%;
  width: 5px;
  height: 5px;
}

#syncButtonPanel{
  float: right;
  position: relative;
  left: -10px;
  top: 1px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

#addViewerPanel{
  float:left;
  position: relative;
  left: 10px;
  top: 1px;
}

.checkmark{
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding-left: 5px;
}

.checkBoxFormLabel{
  text-decoration: underline;
}

.buttonStyle{
  background-color:#009999;
  color: "white";
  border-radius:"5px";
}


#syncButton, .btn-success, #dropdown-item-button, .dropdown-itemm, .closebutton{
  background-color:#009999 !important;
  border-color: #009999 !important;
  border: none;
  box-shadow: none !important;
}

#syncButton:hover, .btn-success:hover, #dropdown-item-button:hover, .dropdown-item, .closebutton:hover{
  background-color: rgb(0, 202, 148) !important;
  border-color: rgb(0, 202, 148) !important;
  transition: background-color 0.5s ease-in-out 0s !important;
}

.teilenButton{
  background-color: #009999;
  border-color: #009999;
  color: white;
  padding: 6px 12px 6px 12px;
  border-radius: 0.25rem;
  font-size: 1rem;
  box-shadow: none;
  border-style: none;
}

.teilenButton:hover{
  background-color: rgb(0, 202, 148);
  transition: background-color 0.5s ease-in-out 0s;
}